import axios from "axios";
import classNames from "classnames";
import { addDays, format } from "date-fns";
import { Link, navigate } from "gatsby";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import Seo from "../components/seo";

function CheckoutSuccess(props) {
  const posthog = usePostHog();

  const [swiftUserId, setSwiftUserId] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setSwiftUserId(localStorage.getItem("swiftUserId"));
    }
  }, []);

  const isSwiftUser = useMemo(() => {
    return !!swiftUserId;
  }, [swiftUserId]);

  if (!props.location.state && typeof window !== "undefined") {
    navigate("/");
  }

  if (!props.location.state) {
    // only for testing
    props.location.state = {
      amountCharged: "$0.00",
      cartContents: [
        {
          id: "window-well-cover-steel-black",
          skus: [],
          bundleId: "f487ad6a-d645-4dd4-ba9b-2e966a793527",
          taxable: true,
          title: "Custom Steel Grate Window Well Cover",
          category: ["covers"],
          basePrice: "430",
          salePrice: "299",
          quantity: 1,
          url: "/window-well-covers/?material=steel&color=black$=&style=standard",
          image:
            "/static/0ab78cc7a788f7528d70958c16cdcb7e/metal-window-well-small.jpg",
          color: "black",
        },
      ],
      tax: "$0.00",
      email: "mike@bootpackdigital.com",
      transactionData: {
        order_id: "test",
      },
      cartTotals: {
        total: "$0.00",
        tax: "$0.00",
      },
    };
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      try {
        const eventData = {
          transaction_id:
            props.location.state.transactionData?.order_id || "test",
          value: +(props.location.state?.cartTotals?.total || "$.00").substr(1),
          currency: "USD",
          tax: +(props.location.state?.cartTotals?.tax || "$.00").substr(1),
          shipping: 0,
          coupon: props.location.state.discount ?? "",
          items: props.location.state.cartContents.map((cartItem, index) => {
            return {
              item_id: cartItem.url.replace(/\//g, ""),
              item_name: cartItem.title,
              item_brand: process.env.COMPANY_NAME,
              index,
              price: cartItem.salePrice
                ? +cartItem.salePrice
                : +cartItem.basePrice,
              category:
                cartItem.url === "/window-well-covers/"
                  ? "Window Well Covers"
                  : "Accessories",
              quantity: cartItem.quantity,
              variant: cartItem.id,
            };
          }),
        };

        try {
          window.gtag("event", "purchase", eventData);
        } catch (error) {
          console.error("gtag error", error);
        }

        try {
          posthog.capture("purchase", eventData);
        } catch (error) {
          console.error("posthog error", error);
        }
      } catch (error) {
        console.error("error", error);
      }
    }
  }, []);

  useEffect(() => {
    if (
      props.location.state.transactionData?.order_id &&
      !localStorage.getItem(
        `checkout-success-${props.location.state.transactionData?.order_id}`
      )
    ) {
      localStorage.setItem(
        `checkout-success-${props.location.state.transactionData?.order_id}`,
        true
      );

      axios.post(`/.netlify/functions/conversion`, {
        email: props.location.state.email,
        checkoutInfo: props.location.state.checkoutInfo,
        cartTotals: props.location.state.cartTotals,
        discount: sessionStorage.getItem("discounts"),
        cartContents: props.location.state.cartContents,
        callInfo: props.location.state.callInfo,
        sourceUrl: sessionStorage.getItem("bpd-first-page"),
        referrer: sessionStorage.getItem("bpd-referrer"),
        value: props.location.state?.cartTotals?.total
          ? +props.location.state.cartTotals.total?.substr(1)
          : 0,
        orderId: props.location.state.transactionData?.order_id,
        gclid: localStorage.getItem("bpd-gclid"),
        twclid: localStorage.getItem("bpd-twclid"),
        fbclid: localStorage.getItem("bpd-fbclid"),
        utm_source: localStorage.getItem("bpd-utm_source"),
        utm_medium: localStorage.getItem("bpd-utm_medium"),
        utm_campaign: localStorage.getItem("bpd-utm_campaign"),
        msclkid: localStorage.getItem("bpd-msclkid"),
        salesRepId: localStorage.getItem("swiftUserId"),
        salesRepName: localStorage.getItem("salesRepName"),
        leadId: localStorage.getItem("quoteId"),
      });
    }
  }, [
    props.location.state?.amountCharged,
    props.location.state?.callInfo,
    props.location.state?.cartContents,
    props.location.state?.cartTotals,
    props.location.state?.checkoutInfo,
    props.location.state?.email,
    props.location.state?.tax,
    props.location.state?.transactionData?.order_id,
  ]);

  useEffect(() => {
    const estimatedDeliveryDate = format(addDays(new Date(), 14), "yyyy-MM-dd");
    // const products = props.location.state.cartContents.map((cartSku) => {
    //   return {
    //     sku: cartSku.url.replace(/\//g, ""),
    //   };
    // })
    function renderOptIn() {
      window.gapi.load("surveyoptin", () => {
        window.gapi.surveyoptin.render({
          merchant_id: process.env.GOOGLE_REVIEWS_MERCHANT_ID,
          order_id: props.location.state.transactionData?.order_id,
          email: props.location.state.email,
          delivery_country: "US",
          estimated_delivery_date: estimatedDeliveryDate,
          // products: products,
          // OPTIONAL FIELDS, do we have this information?
          // products: [{ gtin: "GTIN1" }, { gtin: "GTIN2" }],
        });
      });
    }

    if (typeof window.gapi !== "undefined") {
      renderOptIn();
    } else {
      window.renderOptIn = renderOptIn;
    }

    return () => {
      window.renderOptIn = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Function to check if the page was reloaded
    const handleRefresh = () => {
      if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
        navigate("/");
      }
    };

    window.addEventListener("load", handleRefresh);

    // Cleanup function
    return () => window.removeEventListener("load", handleRefresh);
  }, []);

  return (
    <>
      <Helmet>
        <script
          src="https://apis.google.com/js/platform.js?onload=renderOptIn"
          async
          defer
        ></script>
      </Helmet>

      <Seo title="Thank you for your order!" />
      <div className="lg:flex justify-center flex-wrap gap-4 px-8">
        <div className="md:p-0 w-full max-w-lg my-8 text-grey-darker flex-1">
          <h1 className="mb-4">Thank you for your order!</h1>
          <h3 className="mb-2">
            Order ID: {process.env.ORDER_ID_PREFIX}
            {props.location.state
              ? props.location.state.transactionData?.order_id
              : "NA"}
          </h3>
          <p>
            Amount Charged:{" "}
            {props.location.state ? props.location.state.amountCharged : "NA"}
          </p>
          <hr className="pt-4 mt-4 border-grey-light" />
          <h3 className="pt-8 pb-3">What happens from here</h3>
          <ul>
            <li className="py-2">
              Within 24 hours you will receive a text/call with your template
              date. You do not need to be home as long as we have access to all
              wells.
            </li>
            <li className="py-2">
              After the template we will manufacture your covers (approx 2
              weeks). We will text/call you with the install date when your
              covers are ready.
            </li>
          </ul>
          {!isSwiftUser && (
            <>
              <h4 className="mt-8 mb-2 font-semibold">Get a $50 Gift Card</h4>
              <p className="mb-8">
                We love referrals at {process.env.COMPANY_NAME_SHORT} so if you
                tell a friend or a neighbor about our covers and they place an
                order for two or more covers within 24 hours from now you will
                receive a $50 Visa gift card. The easiest way to get your
                referral bonus is to send your friends and neighbors to our
                website or fb page,{" "}
                <a href={process.env.FACEBOOK_URL}>
                  click here to like us on Facebook!
                </a>
              </p>
            </>
          )}
        </div>
        {isSwiftUser && (
          <div className="md:p-0 w-full max-w-xs my-8 text-grey-darker mt-12 lg:flex lg:justify-center">
            <div>
              <Link
                to="/quote-builder/"
                className={classNames(
                  "p-4 text-white rounded px-6 py-2 tracking-wide no-underline shadow transition-colors",
                  process.env.THEME_COLOR_PRIMARY === "green"
                    ? "bg-green-dark"
                    : "bg-yellow-dark"
                )}
              >
                Start New Order
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default CheckoutSuccess;
