import { useFeatureFlagVariantKey, usePostHog } from "posthog-js/react";
import React from "react";
import Seo from "../components/seo";

const splitTestingGroups = [
  {
    title: "Manual Quantity",
    featureFlagKey: "pdp-manual-quantity",
    variations: [
      {
        title: "control",
        value: undefined,
      },
      {
        title: "manual-quantity",
        value: "manual-quantity",
      },
    ],
  },
];

function SplitTestingPageTemplate() {
  return (
    <>
      <Seo title="Split Testing" />
      <div className="grid gap-16 my-16 px-8 mx-auto max-w-4xl lg:grid-cols-2">
        {splitTestingGroups.map((splitTestingGroup) => (
          <SplitTestingSwitcher
            key={splitTestingGroup.title}
            title={splitTestingGroup.title}
            variations={splitTestingGroup.variations}
            featureFlagKey={splitTestingGroup.featureFlagKey}
          />
        ))}
      </div>
    </>
  );
}

const SplitTestingSwitcher = ({ title, variations, featureFlagKey }) => {
  const posthog = usePostHog();
  const featureFlagVariantKey = useFeatureFlagVariantKey(featureFlagKey);

  return (
    <div>
      <h2 className="text-xl font-bold">{title}</h2>
      <div className="flex flex-wrap gap-x-2 gap-y-1 mt-4">
        {variations.map((variation) => {
          return (
            <button
              key={variation.title}
              onClick={() => {
                posthog.featureFlags.override({
                  [featureFlagKey]: variation.value,
                });
                window.location.reload();
              }}
              className={`py-2 px-3 rounded-md border border-grey-light ${
                featureFlagVariantKey === variation.value
                  ? `bg-${process.env.THEME_COLOR_PRIMARY}-darker text-white`
                  : "bg-grey-lightest"
              }`}
            >
              {variation.title}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default SplitTestingPageTemplate;
